import React from "react"

import SvgIcon from "@material-ui/core/SvgIcon"

const PhoneIcon = props => (
  <SvgIcon {...props} viewBox="0 0 51 51">
    <path
      d="M38.27,33.16c0.3,0.9,0,2.19-0.67,2.86l-1.83,1.83c-0.33,0.33-1.29,0.53-1.32,0.53
	c-5.8,0.05-11.39-2.23-15.5-6.34c-4.12-4.12-6.4-9.73-6.34-15.55c0,0,0.21-0.94,0.54-1.26l1.83-1.83c0.67-0.67,1.96-0.98,2.86-0.68
	l0.39,0.13c0.9,0.3,1.85,1.3,2.09,2.22l0.92,3.39c0.25,0.92-0.09,2.23-0.76,2.9l-1.23,1.22c1.2,4.45,4.7,7.95,9.15,9.15l1.23-1.22
	c0.67-0.67,1.98-1.01,2.9-0.76l3.39,0.93c0.92,0.25,1.91,1.19,2.21,2.09L38.27,33.16z M50.5,25.5c0,13.81-11.19,25-25,25
	s-25-11.19-25-25s11.19-25,25-25S50.5,11.69,50.5,25.5z M47.38,25.5c0-12.06-9.81-21.87-21.88-21.87S3.63,13.44,3.63,25.5
	c0,12.06,9.81,21.88,21.88,21.88S47.38,37.56,47.38,25.5z"
    />
  </SvgIcon>
)

export default PhoneIcon
