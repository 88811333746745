import React, { useState, useContext } from "react"
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from "clsx"
import shortid from "shortid"
import { Link } from "gatsby"

import LangContext from "../../contexts/LangContext"
import LocationContext from "../../contexts/LocationContext"

import Button from "@material-ui/core/Button"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"

const useStyles = makeStyles(theme => ({
  root: {
    position: "relative",
  },
  button: {
    minWidth: 0,
    padding: theme.spacing(1),

    flexShrink: 0,
    fontWeight: 300,
    fontSize: 14,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.aresBlue.main,
    },
  },

  list: {
    padding: 0,
    background: "#fff",
    position: "absolute",
    top: 40,
    left: 0,
  },

  listItem: {
    minWidth: 0,
    padding: theme.spacing(1) / 2,
    flexShrink: 0,
    fontWeight: 300,
    fontSize: 14,
    whiteSpace: "nowrap",

    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.aresBlue.main,
    },
  },

  border: {
    boxShadow: "0px 2px 5px -5px #000",
  },

  activeMenu: {
    color: theme.palette.aresBlue.main,
    fontWeight: 600,
    position: "relative",
    "&:after": {
      content: " ",
      height: 3,
      width: "100%",
      backgroundColor: theme.palette.customGreen.main,
      position: "absolute",
      bottom: -4,
      zIndex: 1000,
    },
  },
}))

const MenuButton = ({ item, showBorder }) => {
  const classes = useStyles()
  const location = useContext(LocationContext)
  const lang = useContext(LangContext)

  const i18n = lang.i18n[lang.locale]

  const [open, setOpen] = useState(false)

  const handleMouseEnter = () => {
    setOpen(true)
  }

  const handleMouseLeave = () => {
    setOpen(false)
  }

  return (
    <div
      className={classes.root}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <Button
        size="small"
        variant="text"
        className={classes.button}
        to={
          i18n.default
            ? `/${item.internal}`
            : `/${i18n.path}/${item.internal}`
        }
        component={Link}>
        {item.label}
      </Button>
      {open && (
        <List
          className={clsx(classes.list, showBorder ? classes.border : "")}
          component="nav"
          aria-label="secondary mailbox folders"
        >
          {item.sublinks.map(subitem => (
            <ListItem
              key={shortid.generate()}
              button
              className={classes.listItem}
            >
              <Button
                size="small"
                variant="text"
                to={
                  i18n.default
                    ? `/${subitem.internal}`
                    : `/${i18n.path}/${subitem.internal}`
                }
                className={
                  location.pathname.match(subitem.internal)
                    ? `${classes.listItem} ${classes.activeMenu}`
                    : `${classes.listItem}`
                }
                component={Link}
              >
                {subitem.label}
              </Button>
            </ListItem>
          ))}
        </List>
      )}
    </div>
  )
}

export default MenuButton
