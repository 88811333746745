import React, { useState, useContext } from "react"
import clsx from "clsx"
import { useStaticQuery, graphql, Link } from "gatsby"
import shortid from "shortid"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"
import ScrollToTop from "react-scroll-up"
import { groupBy } from "lodash"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Button from "@material-ui/core/Button"
import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import Hidden from "@material-ui/core/Hidden"
import IconButton from "@material-ui/core/IconButton"
import Toolbar from "@material-ui/core/Toolbar"

import PublishIcon from "@material-ui/icons/Publish"
import SearchIcon from "@material-ui/icons/Search"
import MenuIcon from "@material-ui/icons/Menu"
import CloseIcon from "@material-ui/icons/Close"

import Logo from "./Logo"
import MobileMenu from "./MobileMenu"

import LangContext from "../../contexts/LangContext"
import LocationContext from "../../contexts/LocationContext"
import { useMainMenu } from "../../contexts/MainMenuProvider"
import { useSearch } from "../../contexts/GlobalSearchProvider"
import MenuButton from "../buttons/MenuButton"

import PhoneIcon from "../icons/PhoneIcon"

const useStyles = makeStyles(theme => ({
  scrollTop: {
    position: "fixed",
    zIndex: 1002,
    bottom: 20,
    right: 20,
  },

  scrollTopButton: {
    background: "rgba(0, 0, 0, 0.04)",

    "&:hover": {
      color: theme.palette.common.white,
      background: theme.palette.aresBlue.main,
    },
  },

  headerWrapper: {
    width: "100%",
    position: "sticky",
    top: 0,
    zIndex: 99999,

    height: 70,
    background: theme.palette.common.white,
    marginBottom: 60,

    [theme.breakpoints.down("sm")]: {
      marginBottom: 30,
      height: 50,
    },
  },

  border: {
    boxShadow: "0px -1px 5px -2px #000",
  },

  header: {
    height: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "flex-start",
    pointerEvents: "none",
  },

  toggleButton: {
    pointerEvents: "auto",
  },

  menuWrapper: {
    position: "fixed",
    width: "100%",
    zIndex: 1001,
    background: theme.palette.common.white,
  },
  toolbarSecondary: {
    justifyContent: "space-between",
    alignItems: "flex-end",
    padding: 0,
    pointerEvents: "auto",
    background: theme.palette.common.white,
    paddingTop: 10,
  },
  toolbarLink: {
    padding: theme.spacing(1),
    flexShrink: 0,
    fontWeight: 300,
    fontSize: 14,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    "&:hover": {
      backgroundColor: "transparent",
      color: theme.palette.aresBlue.main,
    },
  },
  phoneLink: {
    color: theme.palette.common.white,
    fontWeight: 700,
    background: theme.palette.secondary.main,
    borderRadius: 25,
    padding: theme.spacing(1, 2),
    border: '1px solid transparent',

    '&:hover': {
      border: `1px solid ${theme.palette.primary.main}`
    }
  },
  activeMenu: {
    color: theme.palette.aresBlue.main,
    fontWeight: 600,
    position: "relative",
    "&:after": {
      content: " ",
      height: 3,
      width: "100%",
      backgroundColor: theme.palette.customGreen.main,
      position: "absolute",
      bottom: -4,
      zIndex: 1000,
    },
  },
}))

const Header = () => {
  const classes = useStyles()
  const location = useContext(LocationContext)
  const lang = useContext(LangContext)
  const { openSearch } = useSearch()
  const { openMenu, closeMenu } = useMainMenu()
  const [hasBorder, setHasBorder] = useState(false)
  const [anchorEl, setAnchorEl] = useState(null)
  const handleClick = event => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const i18n = lang.i18n[lang.locale]

  const data = useStaticQuery(query)

  const mainMenu = data.mainMenus.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data.links)
    .pop()

  const sublinks = data.mainMenus.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data.sublinks)
    .pop()

  const [open, setOpen] = useState(false)

  const toggleMobileMenu = () => {
    if (open) {
      closeMenu()
    } else {
      openMenu()
    }

    setOpen(!open)
  }

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y > -50) {
        setHasBorder(false)
      } else {
        setHasBorder(true)
      }
    },
    [hasBorder],
    null,
    false,
    0
  )

  const sublinksGrouped = groupBy(sublinks, sl => sl.key)

  mainMenu.map(mm => {
    mm.sublinks = sublinksGrouped[mm.sublinks_key]
  })

  return (
    <div
      className={clsx(classes.headerWrapper, hasBorder ? classes.border : "")}
    >
      <Hidden smDown implementation="css">
        <Container maxWidth="lg" className={classes.header}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={4} sm={2}>
              <Logo />
            </Grid>
            <Grid item sm={10}>
              <Toolbar
                component="nav"
                variant="dense"
                className={classes.toolbarSecondary}
              >
                {mainMenu.map(item => (
                  <div key={shortid.generate()}>
                    {item.sublinks && (
                      <MenuButton item={item} showBorder={hasBorder} />
                    )}
                    {!item.sublinks && (
                      <Button
                        size="small"
                        variant="text"
                        to={
                          i18n.default
                            ? `/${item.internal}`
                            : `/${i18n.path}/${item.internal}`
                        }
                        className={
                          location.pathname.match(item.internal)
                            ? `${classes.toolbarLink} ${classes.activeMenu}`
                            : `${classes.toolbarLink}`
                        }
                        component={Link}
                      >
                        {item.label}
                      </Button>
                    )}
                  </div>
                ))}

                <Button
                  component="a"
                  href="tel:+40319300"
                  size="small"
                  variant="text"
                  className={clsx(classes.toolbarLink, classes.phoneLink)}>
                  <PhoneIcon style={{
                    height: 20,
                    width: 20,
                    marginRight: 4
                  }} />
                  <div>031 9300</div>
                </Button>

                <Button
                  size="small"
                  key={shortid.generate()}
                  variant="text"
                  className={classes.toolbarLink}
                  onClick={openSearch}
                >
                  <SearchIcon style={{ fontSize: 23 }} />
                </Button>
              </Toolbar>
            </Grid>
          </Grid>
        </Container>
      </Hidden>

      <Hidden mdUp implementation="css">
        <Container maxWidth="lg" className={classes.header}>
          <Grid container direction="row" justifyContent="space-between">
            <Grid item xs={4} sm={2}>
              <Logo />
            </Grid>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={toggleMobileMenu}
                className={classes.toggleButton}
              >
                {open ? <CloseIcon /> : <MenuIcon />}
              </IconButton>

              {open && (
                <MobileMenu
                  menu={mainMenu}
                  location={location}
                  toggleMobileMenu={toggleMobileMenu}
                />
              )}
            </Grid>
          </Grid>
        </Container>
      </Hidden>

      <ScrollToTop
        className="scrollUpBtn"
        showUnder={160}
        style={{ bottom: 70, right: 20, zIndex: 9999 }}
      >
        <IconButton className={classes.scrollTopButton} aria-label="delete">
          <PublishIcon />
        </IconButton>
      </ScrollToTop>
    </div>
  )
}

export default Header

const query = graphql`
  query HeaderQuery {
    mainMenus: allPrismicMainMenu {
      edges {
        node {
          lang
          data {
            title
            links {
              label
              internal
              sublinks_key
              url {
                link_type
                url
                target
              }
            }
            sublinks {
              key
              label
              internal
              url {
                link_type
                url
                target
              }
            }
          }
        }
      }
    }
  }
`

Header.propTypes = {}
