import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import makeStyles from "@material-ui/core/styles/makeStyles"

import { isWidthUp, isWidthDown } from "@material-ui/core/withWidth"

import Dialog from "@material-ui/core/Dialog"

import CloseRoundedIcon from "@material-ui/icons/CloseRounded"

import GeneralContact from "./GeneralContact"
import useWidth from "../../services/useWidth"

const useStyles = makeStyles(theme => ({
  close: {
    position: "absolute",
    top: theme.spacing(2),
    right: theme.spacing(2),
    cursor: "pointer",
  },
  contact: {
    paddingTop: theme.spacing(6),
  },
}))

const ContactPopup = ({
  locale,
  location,
  open,
  handleClose,
  selectedHelperUid,
  selectedTeamMemberUid,
  selectedContactLocationUid,
  additionalData = {}
}) => {
  const classes = useStyles()
  const width = useWidth()

  const [modal, setModal] = useState(open)

  useEffect(() => {
    setModal(open)
  }, [open])

  return (
    <Dialog
      fullScreen={isWidthDown("sm", width)}
      open={modal}
      onClose={handleClose}
      PaperProps={{ square: true }}
      style={{ zIndex: 99999 }}
    >
      <div
        className={classes.close}
        onClick={handleClose}
        onKeyDown={handleClose}
        role="button"
        tabIndex="0"
      >
        <CloseRoundedIcon />
      </div>
      <GeneralContact
        className={classes.contact}
        location={location}
        locale={locale}
        dense={isWidthUp("sm", width)}
        selectedHelperUid={selectedHelperUid}
        selectedTeamMemberUid={selectedTeamMemberUid}
        selectedContactLocationUid={selectedContactLocationUid}
        additionalData={additionalData}
      />
    </Dialog>
  )
}

export default ContactPopup

ContactPopup.defaultProps = {
  selectedHelperUid: "-",
  selectedTeamMemberUid: "-",
  selectedContactLocationUid: "-",
  additionalData: {},
}

ContactPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedHelperUid: PropTypes.string,
  selectedTeamMemberUid: PropTypes.string,
  selectedContactLocationUid: PropTypes.string,
  additionalData: PropTypes.object,
}
