// This HEADER is different and used only on the Homepage

import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import shortid from "shortid"

import { default as MaterialLink } from "@material-ui/core/Link"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"


import makeStyles from "@material-ui/core/styles/makeStyles"
import { Link } from "gatsby"

import ExpandMore from "@material-ui/icons/ExpandMore"
import ExpandLess from "@material-ui/icons/ExpandLess"

import LangContext from "../../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  list: {
    background: "#ffffff",
    listStyle: "none",
    padding: 0,
    margin: 0,
    maxHeight: "calc(100vh - 100px)",
    "overflow-y": "scroll",
  },
  item: {
    "&:first-of-type": {
      borderTop: "1px solid #013754",
    },
    color: theme.palette.aresBlue.main,
    borderBottom: "1px solid #013754",
  },
  link: {
    display: "block",
    pointerEvents: "auto",
    lineHeight: "1.2em",

    color: "#fff",
    fontSize: 22,
    textDecoration: "none",
    fontWeight: 400,
    "&:hover": {
      textDecoration: "none",
    },
  },
  sublink: {
    display: "block",
    pointerEvents: "auto",
    lineHeight: "1.2em",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),

    color: "#fff",
    fontSize: 22,
    fontWeight: 400,
    "&:hover": {
      textDecoration: "underline",
    },
  },
}))

const SubMenuItem = ({ item, toggleMobileMenu }) => {
  const classes = useStyles()
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const [open, setOpen] = useState(false)

  const toggleSubmenu = () => {
    setOpen(!open)
  }

  return (
    <div>
      <List
        component="nav"
        aria-labelledby="nested-list-subheader"
        className={classes.root}
      >
        <ListItem button onClick={toggleSubmenu}>
          <ListItemText
            primary={
              <MaterialLink className={classes.link} onClick={toggleSubmenu}>
                {item.label}
              </MaterialLink>
            }
          />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {item.sublinks.map(sublink => (
              <ListItem key={shortid.generate()} button>
                <ListItemText
                  primary={
                    <Link
                      to={
                        i18n.default
                          ? `/${sublink.internal}`
                          : `/${i18n.path}/${sublink.internal}/`
                      }
                      className={classes.sublink}
                      onClick={toggleMobileMenu}
                    >
                      {sublink.label}
                    </Link>
                  }
                />
              </ListItem>
            ))}
          </List>
        </Collapse>
      </List>
    </div>
  )
}

export default SubMenuItem

SubMenuItem.propTypes = {
  item: PropTypes.object.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
}
