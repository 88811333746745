import React, { useContext, useState } from "react"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Grid from "@material-ui/core/Grid"

import ContactPopup from "./ContactPopup"
import CustomButton from "../buttons/CustomButton"
import LocationContext from "../../contexts/LocationContext"
import LangContext from "../../contexts/LangContext"

import PhoneIcon from "../icons/PhoneIcon"
import ScheduleIcon from "../icons/ScheduleIcon"

const useStyles = makeStyles(theme => ({
  root: {
    position: "fixed",
    padding: theme.spacing(1),
    bottom: 0,
    width: "100%",
    zIndex: 1001,
  },

  button: {
    fontSize: 20,
    fontWeight: 700,
    borderRadius: 30
  },
}))

const ContactBar = () => {
  const classes = useStyles()
  const location = useContext(LocationContext)
  const lang = useContext(LangContext)

  const [open, setOpen] = useState(false)

  const i18n = lang.i18n[lang.locale]

  return (
    <div className={classes.root}>
      <Grid container alignItems="center" spacing={1}>
        <Grid item xs={4}>
          <CustomButton
            color="secondary"
            variant="contained"
            fullWidth
            className={classes.button}
            component="a"
            href="tel:+40319300"
          >
            <PhoneIcon />
            &nbsp;{i18n.translations.call}
          </CustomButton>
        </Grid>
        <Grid item xs={8}>
          <CustomButton
            color="secondary"
            variant="contained"
            fullWidth
            className={classes.button}
            onClick={() => setOpen(true)}
          >
            <ScheduleIcon />
            &nbsp;Cere o programare
          </CustomButton>
        </Grid>
      </Grid>
      <ContactPopup
        locale={lang.locale}
        location={location}
        open={open}
        handleClose={() => setOpen(false)}
      />
    </div>
  )
}

export default ContactBar
