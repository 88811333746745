import React, { useContext } from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

import IconButton from "@material-ui/core/IconButton"
import CloseIcon from "@material-ui/icons/Close"

import SearchResults from "./SearchResults"

import LangContext from "../../contexts/LangContext"

import "../../styles/search.scss"

const SearchDialog = ({ onClose }) => {
  const lang = useContext(LangContext)

  return (
    <StaticQuery
      query={graphql`
        query SearchIndexQuery {
          siteSearchIndex {
            index
          }
        }
      `}
      render={data => (
        <div className="searchWrapper">
          <IconButton
            color="inherit"
            onClick={onClose}
            edge="start"
            className="searchCloseBtn"
          >
            <CloseIcon />
          </IconButton>
          <SearchResults
            searchIndex={data.siteSearchIndex.index}
            lang={lang}
            onResultClick={onClose}
          />
        </div>
      )}
    />
  )
}

export default SearchDialog

SearchDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
}
