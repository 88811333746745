import React, { useState } from "react"
import { Index } from "elasticlunr"
import * as shortid from "shortid"
import { Link } from "gatsby"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Container from "@material-ui/core/Container"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"

import TextField from "@material-ui/core/TextField"

import SearchIcon from "@material-ui/icons/Search"

const useStyles = makeStyles(() => ({
  wrapper: {
    marginTop: 16,
    background: "#424041",
  },

  list: {
    padding: 0,
    margin: 0,
    listStyle: "none",
    marginBottom: 16,
  },

  result: {
    color: "#fff",
    cursor: "pointer",
    borderTop: "1px solid rgba(255, 255, 255, 0.3)",

    "&:last-child": {
      borderBottom: "1px solid rgba(255, 255, 255, 0.3)",
    },
  },
  link: {
    padding: "12px 16px",
    display: "block",
    color: "#fff",

    "&:hover": {
      background: "#065ea6",
      textDecoration: "none",
    },
  },
}))

const SearchResults = ({ searchIndex, onResultClick }) => {
  const classes = useStyles()

  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const [index, setIndex] = useState()

  const getOrCreateIndex = () => index || Index.load(searchIndex)

  const onSearch = evt => {
    const q = evt.target.value
    const idx = getOrCreateIndex()
    const res = idx
      .search(evt.target.value, { expand: true })
      .map(({ ref }) => idx.documentStore.getDoc(ref))

    setIndex(idx)
    setQuery(q)
    setResults(res)
  }

  return (
    <Container maxWidth="sm">
      <TextField
        variant="outlined"
        type="text"
        label="Caută"
        fullWidth
        value={query}
        autoFocus
        onChange={onSearch}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                edge="end"
                color="primary"
                aria-label="toggle password visibility"
              >
                <SearchIcon />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <div className={classes.wrapper}>
        <ul className={classes.list}>
          {results.map(page => (
            <li key={shortid.generate()} className={classes.result}>
              <Link
                onClick={() => {
                  onResultClick()
                }}
                to={page.slug}
                className={classes.link}
              >
                {page.title}
              </Link>
            </li>
          ))}
        </ul>
      </div>
    </Container>
  )
}

export default SearchResults
