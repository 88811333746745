import React from "react"

import SvgIcon from "@material-ui/core/SvgIcon"

const ScheduleIcon = props => (
  <SvgIcon {...props} viewBox="0 0 51 51">
    <g>
      <path
        d="M50.5,25.5c0,13.81-11.19,25-25,25s-25-11.19-25-25s11.19-25,25-25S50.5,11.69,50.5,25.5z M47.38,25.5
		c0-12.06-9.81-21.87-21.88-21.87S3.63,13.44,3.63,25.5c0,12.06,9.81,21.88,21.88,21.88S47.38,37.56,47.38,25.5z"
      />
      <g>
        <g>
          <path
            d="M25.5,6.36L25.5,6.36c0.81,0,1.47,0.66,1.47,1.46v0.98c0,0.81-0.66,1.46-1.46,1.46h0
				c-0.81,0-1.46-0.66-1.46-1.46V7.83C24.04,7.02,24.69,6.36,25.5,6.36z"
          />
        </g>
        <g>
          <path
            d="M36.26,12.66l0.69-0.69c0.57-0.57,1.5-0.57,2.07,0l0,0c0.57,0.57,0.57,1.5,0,2.07l-0.69,0.69
				c-0.57,0.57-1.5,0.57-2.07,0l0,0C35.69,14.16,35.69,13.24,36.26,12.66z"
          />
        </g>
        <g>
          <path
            d="M42.19,24.04h0.98c0.81,0,1.46,0.66,1.46,1.46v0c0,0.81-0.66,1.46-1.46,1.46h-0.98
				c-0.81,0-1.46-0.66-1.46-1.46v0C40.73,24.69,41.38,24.04,42.19,24.04z"
          />
        </g>
        <g>
          <path
            d="M36.26,36.27L36.26,36.27c0.57-0.57,1.5-0.57,2.07,0l0.69,0.69c0.57,0.57,0.57,1.5,0,2.07l0,0
				c-0.57,0.57-1.5,0.57-2.07,0l-0.69-0.69C35.69,37.77,35.69,36.84,36.26,36.27z"
          />
        </g>
        <g>
          <path
            d="M25.5,40.73L25.5,40.73c0.81,0,1.47,0.66,1.47,1.46v0.98c0,0.81-0.66,1.46-1.46,1.46h0
				c-0.81,0-1.46-0.66-1.46-1.46v-0.98C24.04,41.38,24.69,40.73,25.5,40.73z"
          />
        </g>
        <g>
          <path
            d="M11.96,36.96l0.69-0.69c0.57-0.57,1.5-0.57,2.07,0l0,0c0.57,0.57,0.57,1.5,0,2.07l-0.69,0.69
				c-0.57,0.57-1.5,0.57-2.07,0l0,0C11.39,38.46,11.39,37.53,11.96,36.96z"
          />
        </g>
        <g>
          <path
            d="M7.83,24.04h0.98c0.81,0,1.46,0.66,1.46,1.46v0c0,0.81-0.66,1.46-1.46,1.46H7.83
				c-0.81,0-1.46-0.66-1.46-1.46v0C6.36,24.69,7.02,24.04,7.83,24.04z"
          />
        </g>
        <g>
          <path
            d="M11.96,11.97L11.96,11.97c0.57-0.57,1.5-0.57,2.07,0l0.69,0.69c0.57,0.57,0.57,1.5,0,2.07l0,0
				c-0.57,0.57-1.5,0.57-2.07,0l-0.69-0.69C11.39,13.47,11.39,12.54,11.96,11.97z"
          />
        </g>
      </g>
      <path
        d="M34.38,26.96H25.5c-0.81,0-1.46-0.66-1.46-1.46v-8.88c0-0.81,0.66-1.46,1.46-1.46h0
		c0.81,0,1.46,0.66,1.46,1.46v7.41h7.41c0.81,0,1.46,0.66,1.46,1.46v0C35.84,26.31,35.19,26.96,34.38,26.96z"
      />
    </g>
  </SvgIcon>
)

export default ScheduleIcon
