import React from "react"

import { Layout } from "./src/components/layout/Layout"
import LocationContext from "./src/contexts/LocationContext"
import LangContext from "./src/contexts/LangContext"
import MainMenuProvider from "./src/contexts/MainMenuProvider"
import GlobalSearchProvider from "./src/contexts/GlobalSearchProvider"
import i18n from "./config/i18n"

const wrapPageElement = ({ element, props }) => {
  const {
    pageContext: { locale },
  } = props

  return (
    <LangContext.Provider value={{ locale, i18n }}>
      <LocationContext.Provider value={props.location}>
        <MainMenuProvider>
          <GlobalSearchProvider>
            <Layout {...props}>{element}</Layout>
          </GlobalSearchProvider>
        </MainMenuProvider>
      </LocationContext.Provider>
    </LangContext.Provider>
  )
}

export default wrapPageElement
