import React from "react"
import makeStyles from '@material-ui/core/styles/makeStyles'
import clsx from "clsx"

import Button from "@material-ui/core/Button"

const useStyles = makeStyles(theme => ({
  root: {
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "initial",
    fontSize: 14,
    color: theme.palette.common.white,

    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))

const CustomButton = props => {
  const classes = useStyles()

  const { className, children } = props

  return (
    <Button {...props} className={clsx(className, classes.root)}>
      {children}
    </Button>
  )
}

export default CustomButton
