import React from "react"
import makeStyles from "@material-ui/core/styles/makeStyles"

import TextField from "@material-ui/core/TextField"

const useStylesAres = makeStyles(theme => ({
  root: {
    overflow: "hidden",
    fontSize: 16,
    borderRadius: 0,
    backgroundColor: "#fcfcfb",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `none`,
    },
  },
  focused: {
    borderColor: "red",
  },
}))

const useLabelStylesAres = makeStyles(() => ({
  root: {
    fontSize: 16,
    backgroundColor: "#fff",
    padding: "0 10px",
    marginLeft: "-5px",

    "&:hover": {
      backgroundColor: "#fff",
    },
    "&$focused": {
      backgroundColor: "#fff",
      boxShadow: `none`,
    },
  },
  focused: {
    backgroundColor: "#fff",
  },
}))

export default function CustomTextField(props) {
  const labelClasses = useLabelStylesAres()
  const classes = useStylesAres()

  const inputProps = {}

  if (props.pattern) {
    inputProps.pattern = props.pattern
  }

  return (
    <TextField
      InputLabelProps={{
        classes: labelClasses,
      }}
      InputProps={{ classes }}
      inputProps={{ ...inputProps }}
      {...props}
      variant="outlined"
      margin="dense"
    />
  )
}
