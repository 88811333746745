// This HEADER is different and used only on the Homepage

import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import shortid from "shortid"
import { Index } from "elasticlunr"

import Container from "@material-ui/core/Container"
import Grid from "@material-ui/core/Grid"
import IconButton from "@material-ui/core/IconButton"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"

import makeStyles from "@material-ui/core/styles/makeStyles"
import { useStaticQuery, graphql, Link } from "gatsby"

import CloseIcon from "@material-ui/icons/Close"
import SearchIcon from "@material-ui/icons/Search"

import LangContext from "../../contexts/LangContext"

import SubMenuItem from "./SubMenuItem"

const useStyles = makeStyles(theme => ({
  wrapper: {
    position: "fixed",
    left: 0,
    right: 0,
    bottom: 0,
    width: "100%",
    height: "100%",
    zIndex: 100000,
    background: theme.palette.aresBlue.main,
    color: "#ffffff",
    pointerEvents: "auto",
  },

  closeButton: {
    color: theme.palette.common.white,
  },
  searchButton: {
    color: theme.palette.common.white,
  },
  list: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    maxHeight: "calc(100vh - 220px)",
    "overflow-y": "scroll",
  },
  item: {
    "&:first-of-type": {
      borderTop: "1px solid #013754",
    },
    borderBottom: "1px solid #013754",
  },
  link: {
    display: "block",
    pointerEvents: "auto",
    color: "#fff",
    lineHeight: "1.2em",
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    fontSize: 22,
    fontWeight: 400,
    "&:hover": {
      textDecoration: "underline",
    },
  },
  inputWrapper: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),

    "& .MuiInputBase-root": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiInputLabel-shrink": {
      backgroundColor: theme.palette.common.white,
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: theme.palette.common.white,
    },
  },
}))

const MobileMenu = ({ menu, location, toggleMobileMenu }) => {
  const classes = useStyles()
  const lang = useContext(LangContext)
  const [query, setQuery] = useState("")
  const [results, setResults] = useState([])
  const [index, setIndex] = useState()

  const data = useStaticQuery(graphql`
    query MobileMenuSearchIndexQuery {
      siteSearchIndex {
        index
      }
    }
  `)

  const getOrCreateIndex = () => index || Index.load(data.siteSearchIndex.index)

  const onSearch = evt => {
    const q = evt.target.value
    const idx = getOrCreateIndex()
    const res = idx
      .search(evt.target.value, { expand: true })
      .map(({ ref }) => idx.documentStore.getDoc(ref))

    setIndex(idx)
    setQuery(q)
    setResults(res)
  }

  const i18n = lang.i18n[lang.locale]

  const renderMenuItem = item => {
    if (item.sublinks) {
      return (
        <li key={shortid.generate()} className={classes.item}>
          <SubMenuItem item={item} toggleMobileMenu={toggleMobileMenu} />
        </li>
      )
    }

    return (
      <li key={shortid.generate()} className={classes.item}>
        <Link
          to={
            i18n.default
              ? `/${item.internal}`
              : `/${i18n.path}/${item.internal}/`
          }
          className={classes.link}
          onClick={toggleMobileMenu}
        >
          {item.label}
        </Link>
      </li>
    )
  }

  return (
    <Grid
      className={classes.wrapper}
      container
      direction="column"
      justifyContent="space-between"
      alignItems="stretch"
      style={{ height: "100%" }}
    >
      <Grid item>
        <Container>
          <Grid
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
            spacing={1}
          >
            <Grid item xs={10}>
              <div className={classes.inputWrapper}>
                <TextField
                  variant="outlined"
                  type="text"
                  label="Caută"
                  fullWidth
                  value={query}
                  onChange={onSearch}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          color="primary"
                          aria-label="toggle password visibility"
                        >
                          <SearchIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </Grid>
            <Grid item xs={2}>
              <IconButton
                onClick={toggleMobileMenu}
                className={classes.closeButton}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </Container>
        <nav>
          {results.length <= 0 && (
            <ul className={classes.list}>
              {menu.map(item => renderMenuItem(item))}
            </ul>
          )}

          {results.length > 0 && (
            <ul className={classes.list}>
              {results.map(page => (
                <li key={shortid.generate()} className={classes.item}>
                  <Link
                    onClick={toggleMobileMenu}
                    to={page.slug}
                    className={classes.link}
                  >
                    {page.title}
                  </Link>
                </li>
              ))}
            </ul>
          )}
        </nav>
      </Grid>
    </Grid>
  )
}

export default MobileMenu

MobileMenu.propTypes = {
  menu: PropTypes.oneOfType([PropTypes.array]).isRequired,
  location: PropTypes.object.isRequired,
  toggleMobileMenu: PropTypes.func.isRequired,
}
