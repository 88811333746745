import React, { useContext, useRef, useState } from "react"

import PropTypes from "prop-types"

import makeStyles from "@material-ui/core/styles/makeStyles"
import { isWidthUp } from "@material-ui/core/withWidth"
import clsx from "clsx"

import Typography from "@material-ui/core/Typography"

import Grid from "@material-ui/core/Grid"
import CheckBoxOutlineBlankRoundedIcon from '@material-ui/icons/CheckBoxOutlineBlankRounded';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import axios from "axios"
import * as qs from "query-string"
import LangContext from "../../contexts/LangContext"
import CustomTextField from "../form/CustomTextField"
import CustomButton from "../buttons/CustomButton"

import useWidth from "../../services/useWidth"
import { Checkbox, FormControlLabel } from "@material-ui/core"

const useStyles = makeStyles(theme => ({
  root: {
    background: "#f7941d",
    padding: theme.spacing(3),
    height: "100%",

    [theme.breakpoints.down("sm")]: {},
    [theme.breakpoints.down("xs")]: {},
  },
  field: {
    borderRadius: 0,
    background: theme.palette.common.white,
  },
  infoText: {
    color: theme.palette.common.white,
    fontSize: 12,
    textAlign: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
  sendButton: {
    marginTop: theme.spacing(1),
    boxShadow: "none",
    borderRadius: 0,
    textTransform: "initial",
    fontSize: 14,
  },
  successMessage: {
    color: theme.palette.common.white,
    fontSize: 18,
    textAlign: "center",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}))

const GeneralContact = ({
  formName,
  location,
  selectedHelperUid,
  selectedTeamMemberUid,
  selectedContactLocationUid,
  spread,
  contactMessage,
  disclaimerMessage,
  redirectTo,
  sendToCRM,
  additionalData = {},
  ...props
}) => {
  const classes = useStyles()
  const width = useWidth()

  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const formNameRef = useRef(null)
  const sourceRef = useRef(null)
  const nameRef = useRef(null)
  const emailRef = useRef(null)
  const phoneRef = useRef(null)
  const messageRef = useRef(null)
  // const newsletterRef = useRef(null)
  const helperRef = useRef(null)
  const teamMemberRef = useRef(null)
  const contactLocationRef = useRef(null)

  const [submitted, setSubmitted] = useState(false)
  const [loading, setLoading] = useState(false)

  const { className } = props

  if (!disclaimerMessage || disclaimerMessage.length <= 0) {
    disclaimerMessage = i18n.translations.contactDisclaimer
  }

  if (!contactMessage || contactMessage.length <= 0) {
    contactMessage = i18n.translations.contactMessage
  }

  const send_site_data = (utterance, entity_type, entity_data) => {
    try {
      utterance = utterance || "_send_client_data_"
      entity_type = entity_type || "SiteData"
      trigger_flow(utterance, entity_type, entity_data)
    } catch (e) { }
  }

  const trigger_flow = (utterance, entity_type, entity_data) => {
    const payload = {
      name: utterance,
      value: {
        $entityTypeName$: entity_type,
        ...entity_data,
      },
    }
    DruidWebchat_v2.sendEvent(payload)
  }

  const handleSubmit = event => {
    event.preventDefault()

    setLoading(true)

    const formData = {
      "form-name": formNameRef.current.value,
      source: sourceRef.current.value,
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      helper: helperRef.current.value,
      teamMember: teamMemberRef.current.value,
      contactLocation: contactLocationRef.current.value,
      // newsletter: newsletterRef.current.checked,
      message: messageRef.current.value,
      ...additionalData
    }

    if (sendToCRM) {
      send_site_data("_send_client_data_", "SiteData", {
        ContactFormSite: formNameRef.current.value,
        Origin: sourceRef.current.value,
        FirstName: nameRef.current.value,
        Email: emailRef.current.value,
        MainPhone: phoneRef.current.value,
        Helper: helperRef.current.value,
        Medic: teamMemberRef.current.value,
        ContactLocation: contactLocationRef.current.value,
        InfoAresSite: messageRef.current.value,
        // Newsletter: newsletterRef.current.checked,
      })
    }


    const axiosOptions = {
      url: location.pathname,
      method: "post",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      data: qs.stringify(formData),
    }

    axios(axiosOptions)
      .then(response => {
        //  setSubmitted(true)
        //  setLoading(false)
        //  window.history.pushState("", "", `${location.pathname}multumim/`)
      })
      .catch(err => {
        console.log(err)
        // setSubmitted(false)
        // setLoading(false)
      })

    setSubmitted(true)
    setLoading(false)

    if (redirectTo) {
      window.history.pushState("", "", redirectTo)
    }
  }

  let dense = isWidthUp("sm", width)

  if (spread) {
    dense = false
  }

  return (
    <div className={clsx(classes.root, className)}>
      {submitted && (
        <Typography className={classes.successMessage}>
          {i18n.translations.messageReceivedConfirmation}
        </Typography>
      )}
      {!submitted && (
        <>
          <form
            name={formName}
            method="POST"
            data-netlify="true"
            netlify-honeypot="bot-field"
            onSubmit={event => handleSubmit(event)}
          >
            <input
              type="hidden"
              name="form-name"
              value={formName}
              ref={formNameRef}
            />

            <input
              type="hidden"
              name="source"
              value={location.href}
              ref={sourceRef}
            />

            <input
              type="hidden"
              name="helper"
              value={selectedHelperUid}
              ref={helperRef}
            />

            <input
              type="hidden"
              name="teamMember"
              value={selectedTeamMemberUid}
              ref={teamMemberRef}
            />

            <input
              type="hidden"
              name="contactLocation"
              value={selectedContactLocationUid}
              ref={contactLocationRef}
            />

            {Object.keys(additionalData).map(key => (
              <input
                type="hidden"
                name={key}
                key={`add_data_${key}`}
                value={additionalData[key]}
              />
            ))}

            <p className="hidden">
              <label htmlFor="bot-field">
                Don’t fill this out if you're human:
                <input id="bot-field" name="bot-field" />
              </label>
            </p>

            <Grid container spacing={dense ? 2 : 0}>
              <Grid item xs={dense ? 4 : 12}>
                <CustomTextField
                  fullWidth
                  type="text"
                  id="nameInput"
                  label={i18n.translations.contactName}
                  name="name"
                  inputRef={nameRef}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={dense ? 4 : 12}>
                <CustomTextField
                  fullWidth
                  type="email"
                  id="emailInput"
                  label={i18n.translations.contactEmail}
                  name="email"
                  inputRef={emailRef}
                  disabled={loading}
                />
              </Grid>
              <Grid item xs={dense ? 4 : 12}>
                <CustomTextField
                  fullWidth
                  type="tel"
                  pattern="[0-9\/|+\-\s]+"
                  id="phoneInput"
                  label={i18n.translations.contactPhone}
                  name="phone"
                  inputRef={phoneRef}
                  disabled={loading}
                  required
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  fullWidth
                  id="messageInput"
                  label={contactMessage}
                  multiline
                  name="message"
                  inputRef={messageRef}
                  rows={7}
                  disabled={loading}
                />
              </Grid>
              {/* 
              <Grid item xs={12}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputRef={newsletterRef}
                      icon={<CheckBoxOutlineBlankRoundedIcon style={{ fontSize: 15, color: "#fff" }} />}
                      checkedIcon={<CheckBoxRoundedIcon style={{ fontSize: 15, color: "#fff" }} />}
                    />
                  }
                  label={<Typography style={{ color: "#fff", fontSize: 12 }}>
                    Sunt de acord să primesc Newsletter din partea ARES
                  </Typography>}
                />
              </Grid>
              */}
            </Grid>

            <Typography
              className={classes.infoText}
              dangerouslySetInnerHTML={{
                __html: disclaimerMessage,
              }}
            />

            <CustomButton
              fullWidth
              type="submit"
              variant="contained"
              color="primary"
              className={classes.sendButton}
              disabled={loading}
            >
              {i18n.translations.sendMessage}
            </CustomButton>
          </form>
        </>
      )}
    </div>
  )
}

export default GeneralContact

GeneralContact.defaultProps = {
  formName: 'Contact Form',
  selectedHelperUid: "-",
  selectedTeamMemberUid: "-",
  selectedContactLocationUid: "-",
  spread: false,
  disclaimerMessage: "",
  sendToCRM: true
}

GeneralContact.propTypes = {
  formName: PropTypes.string,
  location: PropTypes.object.isRequired,
  locale: PropTypes.string,
  selectedHelperUid: PropTypes.string,
  selectedTeamMemberUid: PropTypes.string,
  selectedContactLocationUid: PropTypes.string,
  spread: PropTypes.bool,
  contactMessage: PropTypes.bool,
  disclaimerMessage: PropTypes.string,
  redirectTo: PropTypes.string,
  sendToCRM: PropTypes.bool,
}
