import React, { useState, useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"
import { isMobile } from "react-device-detect"

import getUnixTime from "date-fns/getUnixTime"
import fromUnixTime from "date-fns/fromUnixTime"
import subHours from "date-fns/subHours"
import isBefore from "date-fns/isBefore"
import isValid from "date-fns/isValid"

import makeStyles from "@material-ui/core/styles/makeStyles"

import Button from "@material-ui/core/Button"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import Typography from "@material-ui/core/Typography"


import LangContext from "../contexts/LangContext"

const useStyles = makeStyles(theme => ({
  title: {
    textAlign: "center",
  },
  actions: {
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
}))

const GlobalAnnouncement = () => {
  const lang = useContext(LangContext)
  const i18n = lang.i18n[lang.locale]

  const classes = useStyles()

  const data = useStaticQuery(query)

  const homepage = data.homepages.edges
    .filter(edge => edge.node.lang === lang.locale)
    .map(r => r.node.data)
    .pop()

  const [open, setOpen] = useState(() => {
    const lastTime = fromUnixTime(
      typeof window !== "undefined" &&
      window.localStorage.getItem("announcement-last-display")
    )

    return !isValid(lastTime) || isBefore(lastTime, subHours(new Date(), 6))
  })

  const handleClose = () => {
    if (typeof window !== "undefined") {
      window.localStorage.setItem(
        "announcement-last-display",
        getUnixTime(new Date())
      )
    }

    setOpen(false)
  }

  return (
    <>
      {homepage.announcement_active && (
        <Dialog
          elevation={5}
          fullWidth
          fullScreen={isMobile}
          maxWidth="md"
          open={open}
        >
          <DialogTitle className={classes.title}>
            {homepage.announcement_title.text}
          </DialogTitle>
          <DialogContent>
            <DialogContentText />
            <Typography
              component={DialogContentText}
              dangerouslySetInnerHTML={{
                __html: homepage.announcement_content.html,
              }}
            />
          </DialogContent>
          <DialogActions className={classes.actions}>
            <Button onClick={handleClose} variant="contained" color="primary">
              {i18n.translations.close}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default GlobalAnnouncement

const query = graphql`
  query GlobalAnnouncementQuery {
    homepages: allPrismicHomepage {
      edges {
        node {
          lang
          data {
            announcement_active
            announcement_content {
              html
            }
            announcement_title {
              text
            }
          }
        }
      }
    }
  }
`

GlobalAnnouncement.propTypes = {}
