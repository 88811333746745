import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import CssBaseline from "@material-ui/core/CssBaseline"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"

import theme from "../../styles/theme"
import Header from "./Header"

import Footer from "./Footer"

import GlobalAnnouncement from "../GlobalAnnouncement"

// Additional styles
import "../../styles/custom.css"
import "../../styles/homeMenu.scss"
import "../../styles/base.scss"

const Layout = ({ children, pageContext, location }) => {
  const [contentScrollable, setContentScrollable] = useState(true)

  const toggleContent = () => {
    setContentScrollable(!contentScrollable)
  }

  useEffect(() => {
    DruidWebchat_v2.init({
      botId: "0797c4f0-000d-456e-9358-08db005f097c",
      queryParams: "", // pass queryParams to AuthorizeAsync method
      baseUrl: "https://druidapi.druidplatform.com",
      UI: {
        "autoExpandEnabled": true
      }
    });
  }, [])

  return (
    <MuiThemeProvider theme={theme}>
      <CssBaseline />
      <Header />
      <div className="mainContainer">{children}</div>
      <Footer style={{ position: contentScrollable ? "static" : "fixed" }} />
      <GlobalAnnouncement />
    </MuiThemeProvider>
  )
}

export { Layout }

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  pageContext: PropTypes.shape({
    locale: PropTypes.string.isRequired,
  }).isRequired,
  location: PropTypes.object.isRequired,
}
